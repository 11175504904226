import { Layout } from 'antd'
const image = require('../../img/logo/logo.png');

const TopHeader = () => {
  const { Header } = Layout;
  return (
    <Header
      className='default-header'
    >
      <img
        className='header-logo'
        src={image}
        alt='Scratch Lab'
        onClick={() => (window.location.href = '/')}
      />
      <a href="https://lin.ee/hx062UM" target="_blank" rel="noreferrer">
        Contact Us
      </a>
    </Header>
  )
}

export default TopHeader
