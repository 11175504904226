import { useEffect, useState } from 'react'
import axios from 'axios'
import { HomeOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout, Spin, Table, Button } from 'antd'
import TopHeader from '../elements/topHeader'
import SectionHeader from '../elements/sectionHeader'
import BottomFooter from '../elements/bottomFooter'
import BreadcrumbMenu from '../elements/breadcrumbMenu'
import { CALL_API, showNotification } from '../../constant'

const ClassPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const { Content } = Layout
  const location = useLocation()
  const navigate = useNavigate()
  const {
    theme_id,
    class_id,
    course_name,
    level,
    theme_name,
    price,
    courses,
    theme_image,
    theme_description,
  } = location?.state || {}

  const breadcrumbList = [
    { title: <HomeOutlined />, href: '/theme' },
    {
      title: (
        <a
          onClick={() => {
            navigate(`/theme/course`, {
              state: {
                id: theme_id,
                theme_name,
                courses,
                theme_image,
                theme_description,
              },
            })
          }}
        >
          {theme_name}
        </a>
      ),
    },
    { title: `${course_name}` },
  ]

  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      render: (render, record) => (record.isFirstIndex ? `${render}` : ' '),
    },
    {
      title: 'เวลา',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'คุณครู',
      key: 'teacher',
      render: (render) =>
        `${render.teacher.firstname} ${render.teacher.lastname}`,
    },
    {
      title: 'จำนวนที่นั่ง',
      key: 'inClass',
      render: (render) => `${render.inClass}/${render.maxClass}`,
    },
    {
      title: 'จองรอบ',
      render: (render) => (
        <Button
          className="default-button green-button cta-button"
          disabled={render.inClass === render.maxClass}
          onClick={() => {
            const state = {
              theme_id,
              theme_name,
              class_id,
              course_name,
              level,
              class_date: render.date,
              class_time: render.time,
              teacher_name: `${render.teacher.firstname} ${render.teacher.lastname}`,
              price,
              courses,
            }
            checkClass(state)
          }}
        >
          คลิก
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (location?.state) {
      fetchSchedule()
    } else {
      navigate(`/theme`, {})
    }
  }, [])

  const checkClass = async (state) => {
    setLoading(true)
    try {
      const { data } = await axios.post(CALL_API.CHECKSCHEDULE, {
        course_name,
        class_date: state.class_date,
        class_time: state.class_time,
      })
      console.log(data.result)
      if (data.result) {
        navigate(`/theme/course/class/reserve`, {
          state,
        })
      } else {
        showNotification('info', 'This class had been full')
        navigate(`/theme/course/class`, {
          state: {
            theme_id,
            class_id,
            course_name,
            level,
            theme_name,
            price,
            courses,
            theme_image,
            theme_description,
          },
        })
      }
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const fetchSchedule = async () => {
    setLoading(true)
    try {
      const result = await axios.post(CALL_API.LISTSCHEDULE, { course_name })
      const classList = []
      result.data.forEach((value) => {
        value.schedule.forEach((element, index) => {
          const schedule = {
            isFirstIndex: index === 0 ? true : false,
            ...element,
          }
          classList.push(schedule)
        })
      })
      setData(classList)
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout className="section-content">
      <TopHeader />
      <SectionHeader
        bgImage={theme_image}
        topText={theme_name}
        description={theme_description}
      />
      <Spin spinning={loading}>
        <Content id="class-page" className="site-layout">
          <BreadcrumbMenu breadcrumbList={breadcrumbList} />
          {/* <div className="content-bg"> */}
          <div>
            <Table
              className="full-width"
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </Content>
      </Spin>
      <BottomFooter />
    </Layout>
  )
}

export default ClassPage
