import React, { useEffect, useState } from 'react'
import { Form, Modal, Button } from 'antd'
import axios from 'axios'
import { CALL_API, checkAdminPath, showNotification } from '../../constant'
import SlipPage from '../elements/slip'
import SubModal from './subModal'

const ModalTransactionPage = ({
  slipView,
  setSlipView,
  id,
  fetchList,
  setIsValid,
  navigate,
}) => {
  const [datas, setDatas] = useState({})
  const [titleAction, setTitleAction] = useState('')
  const [subModalVisible, setSubModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (id) fetchData()
  }, [id])

  const fetchData = async () => {
    setLoading(true)
    try {
      const result = await axios.post(CALL_API.GETAPPROVAL, { id: id })
      setDatas(result.data)
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    checkAdminPath(setIsValid, navigate)
    setSlipView(false)
    setSubModalVisible(false)
    setDatas('')
    setTitleAction('')
  }

  const handleApprove = () => {
    setSubModalVisible(true)
    setSlipView(false)
    setTitleAction('Approve')
  }

  const handleReject = () => {
    setSubModalVisible(true)
    setSlipView(false)
    setTitleAction('Reject')
  }

  const handleConfirm = (type) => {
    const object = {}
    switch (type) {
      case 'Approve':
        Object.assign(object, {
          body: {
            id: datas.id,
            link: `https://${form.getFieldValue('Link')}`,
          },
          path: CALL_API.APPROVE,
        })
        break
      case 'Reject':
        Object.assign(object, {
          body: {
            id: datas.id,
            reason: form.getFieldValue('Reason'),
          },
          path: CALL_API.REJECT,
        })
        break
    }
    form.setFieldsValue('Link', '')
    form.setFieldsValue('Reason', '')
    setTitleAction('')
    setDatas('')
    submitData(object)
  }

  const submitData = async (data) => {
    setLoading(true)
    try {
      const result = await axios.post(data.path, data.body)
      showNotification('success', result.data)
      fetchList()
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        title="View slip"
        open={slipView}
        onOk={handleApprove}
        onCancel={handleCancel}
        footer={
          datas.status === 0
            ? [
                <Button type="primary" key="approve" onClick={handleApprove}>
                  Approve
                </Button>,
                <Button
                  type="primary"
                  danger
                  key="reject"
                  onClick={handleReject}
                >
                  Reject
                </Button>,
              ]
            : []
        }
      >
        <hr style={{ width: '100%', borderTop: '1px solid black' }}></hr>
        <SlipPage form={form} data={datas} loading={loading} />
      </Modal>
      <SubModal
        form={form}
        titleAction={titleAction}
        setTitleAction={setTitleAction}
        subModalVisible={subModalVisible}
        setSubModalVisible={setSubModalVisible}
        setSlipView={setSlipView}
        handleConfirm={handleConfirm}
        setIsValid={setIsValid}
        navigate={navigate}
      />
    </>
  )
}

export default ModalTransactionPage
