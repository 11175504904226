import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Input } from 'antd'
import { checkAdminPath, formItemLayout, rules } from '../../constant'
import TextArea from 'antd/es/input/TextArea'

const SubModal = ({
  form,
  titleAction,
  setTitleAction,
  subModalVisible,
  setSubModalVisible,
  setSlipView = false,
  handleConfirm,
  setIsValid,
  navigate,
}) => {
  const handleSubOk = () => {
    checkAdminPath(setIsValid, navigate)
    setSubModalVisible(false)
    setSlipView(false)
    handleConfirm(titleAction)
  }

  const handleSubCancel = () => {
    setSubModalVisible(false)
    setSlipView(true)
    setTitleAction('')
  }

  const contentSubModal = (title) => {
    let render
    let element
    switch (title) {
      case 'Approve':
        render = 'Link'
        element = <Input prefix="https://" />
        break
      case 'Reject':
        render = 'Reason'
        element = <TextArea />
        break
      default:
        break
    }

    return (
      <Form className="form-container" {...formItemLayout} form={form}>
        <Form.Item name={render} label={render} rules={rules(render)}>
          {element}
        </Form.Item>
      </Form>
    )
  }

  return (
    <Modal
      title={`${titleAction} reservation`}
      open={subModalVisible}
      onOk={handleSubOk}
      onCancel={handleSubCancel}
      footer={[
        <Button key="cancel" onClick={handleSubCancel}>
          Cancel
        </Button>,
        <Button type="primary" key="confirm" onClick={handleSubOk}>
          Confirm
        </Button>,
      ]}
    >
      {contentSubModal(titleAction)}
    </Modal>
  )
}

export default SubModal
