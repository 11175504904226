import { Layout } from 'antd'
const image = require('../../../img/logo/logo.png');

const AdminHeader = () => {
  const { Header } = Layout;
  return (
    <Header
      className='default-header'
    >
      <img
        className='header-logo'
        src={image}
        alt='Scratch Lab'
        onClick={() => (window.location.href = '/admin')}
      />
    </Header>
  )
}

export default AdminHeader
