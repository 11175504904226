import { Form, Image, Spin } from 'antd'
import { formItemLayout } from '../../constant'

const SlipPage = ({ form, data, loading }) => {
  return (
    <Spin spinning={loading}>
      <Form
        className='form-container'
        form={form}
        {...formItemLayout}
      >
        <Form.Item label="Course Name">{data?.course?.course_name}</Form.Item>
        <Form.Item label="Level">{data?.course?.level}</Form.Item>
        <Form.Item label="Client Name">
          {data?.client?.firstname} {data?.client?.lastname}
        </Form.Item>
        <Form.Item label="Time">{data?.course?.class_time}</Form.Item>
        <Form.Item label="Slip">
          <Image width={200} src={data?.payment?.bills} />
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default SlipPage
