import { notification } from 'antd'

const CALL_API = {
  // API mock call check
  CHECK: `/v1/check`,
  BANK: `/v1/bank`,

  // API LIST data
  LISTAPPROVAL: `/v1/listApproval`,
  LISTTEACHER: `/v1/listTeacher`,
  LISTTHEME: `/v1/listTheme`,
  LISTREVIEW: `/v1/listReview`,

  // API GET data use POST
  /**
   * LISTCOURSE.
   *
   * @body { courses }
   * @example_body { "courses": ["Course 01", "Course 09"] }
   * @return array of object course.
   */
  LISTCOURSE: `/v1/listCourse`,
  /**
   * LISTSCHEDULE.
   *
   * @body { course_name }
   * @example_body { "course_name": "Course 01" }
   * @return array of object schedule and map teacher time.
   */
  LISTSCHEDULE: `/v1/listSchedule`,
  /**
   * GETAPPROVAL.
   *
   * @body { id }
   * @example_body { "id": "xxxxxxxxxxx" }
   * @return get detail of approval user.
   */
  GETAPPROVAL: `/v1/getApproval`,

  /**
   * CHECKSCHEDULE.
   *
   * @body { course_name, class_date, class_time }
   * @example_body { "course_name": "Course 01", class_date: "2023-01-02", class_time: "09:00 - 10:00" }
   * @return boolean class.
   */
  CHECKSCHEDULE: `/v1/checkSchedule`,

  // API reserve data
  CREATE: `/v1/reserve`,

  /**
   * APPROVE.
   *
   * @body { id, link }
   * @example_body { "id": "xxxxxxxxxxx", link: "https://xxxxxxxxxxx" }
   * @return
   */
  APPROVE: `/v1/approve`,
  /**
   * REJECT.
   *
   * @body { id, reason }
   * @example_body { "id": "xxxxxxxxxxx", reason: "False transaction" }
   * @return
   */
  REJECT: `/v1/reject`,

  ADMIN_VALIDATE: `/v1/admin/validate`,
  ADMIN_APPROVAL: (id) => `/v1/admin/return/${id}`,
}

const VALIDATEADMINTYPE = {
  VERIFY: 'verifyAdmin',
  LOGIN: 'loginAdmin',
}

const status_client = {
  0: 'Waiting for Approve',
  1: 'Approved',
  2: 'Reject',
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

const status_check = {
  default: 'UNKNOW',
  success: 'OK',
  error: 'FAILED',
}

const regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const regex_phone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

const rules = (name) => {
  return [{ required: true, message: `Please filled ${name}!` }]
}

const checkAdminPath = (setIsValid, navigate) => {
  const path = window.location.pathname.split('/')
  if (path.length > 2) {
    setIsValid(true)
    navigate(`/${path[1]}`)
    // window.location.href = `/${path[1]}`
  }
}

const showNotification = (type, message) => {
  let noti
  if (type === 'error') {
    noti = notification.error({
      message: 'Error',
      description: message,
      placement: 'top',
      style: { width: '50vw' },
    })
  } else if (type === 'success') {
    noti = notification.success({
      message: 'success',
      description: message,
      placement: 'top',
      style: { width: '50vw' },
    })
  } else if (type === 'info') {
    noti = notification.info({
      description: message,
      placement: 'top',
      style: { width: '50vw' },
    })
  }
  return noti
}

export {
  CALL_API,
  VALIDATEADMINTYPE,
  status_client,
  formItemLayout,
  status_check,
  regex_email,
  regex_phone,
  rules,
  checkAdminPath,
  showNotification,
}
