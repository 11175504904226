import { Card } from 'antd'

const titleEle = (val) => {
  return (
    <>
      <div>• {val.name} •</div>
      <div>• {val.title} •</div>
    </>
  );
}

const Review = ({ dataList }) => {
  const { Meta } = Card
  const dataEle = dataList.map((val) => {
    return (
      <Card
        key={val.id}
        className="card-item review-item"
        hoverable
        cover={
          <img
            className='review-image'
            src={val.image}
            alt={val.name}
          />
        }
      >
        <Meta title={titleEle(val)} description={val.message} />
      </Card>
    )
  })
  return (
    <div id="review-section" >
      <div className='m-bottom-15'>
        <span className='page-header'>รีวิวจากนักเรียนในคลาส</span>
      </div>
      <div className='content-bg'>
        {dataEle}
      </div>
    </div>
  )
}

export default Review
