import './App.css'
import { Route, Routes } from 'react-router-dom'
import AdminPage from './components/admin/admin'
import MockPage from './components/mock'
import ReservePage from './components/client/reserve'
import ThemePage from './components/client/theme'
import CoursePage from './components/client/course'
import ApprovalPage from './components/admin/approval'
import ClassPage from './components/client/class'
import RedirectPage from './components/admin/redirect'
import ErrorPage from './components/404'
import { createContext, useEffect, useState } from 'react'

const AdminContext = createContext()

function App() {
  const [isValid, setIsValid] = useState(false)

  return (
    <AdminContext.Provider value={{ isValid, setIsValid }}>
      <Routes>
        <Route path="/" element={<ThemePage />} />
        <Route path="/theme">
          <Route path="" element={<ThemePage />} />
          <Route path="course">
            <Route path="" element={<CoursePage />} />
            <Route path="class">
              <Route path="" element={<ClassPage />} />
              <Route path="reserve" element={<ReservePage />} />
            </Route>
          </Route>
        </Route>
        <Route path="/admin">
          <Route path="" element={<AdminPage />} />
          <Route path="approval/:uid" element={<AdminPage />} />
        </Route>
        <Route path="/redirect/:uid" element={<RedirectPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AdminContext.Provider>
  )
}

export { AdminContext }
export default App
