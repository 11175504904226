import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HomeOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Breadcrumb,
  Layout,
  Button,
  Table,
  Spin,
  notification,
  Input,
  DatePicker,
  Divider,
  Form,
  Checkbox,
} from 'antd'
import axios from 'axios'

import { CALL_API, VALIDATEADMINTYPE, status_client } from '../../constant'
import ModalTransactionPage from './modalTransaction'
import AdminHeader from '../elements/admin/adminHeader'
import AdminBreadcrumbMenu from '../elements/admin/adminBreadcrumbMenu'
import { AdminContext } from '../../App'

const AdminPage = () => {
  const [arr, setArr] = useState([])
  const [data, setData] = useState([])
  const [slipView, setSlipView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [time, setTime] = useState([])
  const [login, setLogin] = useState(false)

  const { RangePicker } = DatePicker
  const { Content } = Layout

  const params = useParams()
  const { uid } = params

  const navigate = useNavigate()

  const { isValid, setIsValid } = useContext(AdminContext)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Course Name',
      render: (record) => record.course.course_name, // Access nested value using custom render function
    },
    {
      title: 'Level',
      render: (record) => record.course.level, // Access nested value using custom render function
    },
    {
      title: 'Client Name',
      render: (record) =>
        `${record.client.firstname} ${record.client.lastname}`, // Access nested value using custom render function
    },
    {
      title: 'Time',
      render: (record) => record.course.class_time, // Access nested value using custom render function
    },
    {
      title: 'Status',
      render: (record) => status_client[record.status], // Access nested value using custom render function
    },
    {
      title: 'Slip',
      render: (record) => buttonComponent(record.id, record.payment.bills), // Access nested value using custom render function
    },
    {
      title: 'Link',
      render: (record) => linkComponent(record.course?.link), // Access nested value using custom render function
    },
  ]

  const linkComponent = (link) => {
    if (link)
      return (
        <Button
          type="primary"
          onClick={() => {
            window.open(
              `https://${link}`,
              '_blank', // <- This is what makes it open in a new window.
            )
          }}
        >
          Link
        </Button>
      )
    else return null
  }

  const buttonComponent = (id, bills) => {
    return (
      <Button
        onClick={() => {
          console.log(id)
          setSlipView(true)
          setId(id)
        }}
      >
        view
      </Button>
    )
  }

  useEffect(() => {
    if (!slipView) {
      setId('')
    }
  }, [slipView])

  useEffect(() => {
    // bypass create token name AdminCard
    const cookie = getCookie('AdminCard')
    if (cookie !== null && cookie !== undefined && cookie !== '') {
      validateAdmin({ cookies: cookie }, VALIDATEADMINTYPE.VERIFY)
      fetchList()
    } else {
      setLogin(false)
      // window.location.href = `/error`
    }
  }, [login])

  function getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  useEffect(() => {
    if (uid) {
      setId(uid)
      setSlipView(true)
    }
  }, [uid])

  const fetchList = async () => {
    setLoading(true)
    try {
      const result = await axios.get(CALL_API.LISTAPPROVAL)
      setArr(result.data)
      setData(result.data)
    } catch (err) {
      notification.error({
        message: 'error',
        description: err.response.data,
        placement: 'top',
        style: { width: '50vw' },
      })
    } finally {
      setLoading(false)
    }
  }

  const validateAdmin = async (data, type) => {
    setLoading(true)
    try {
      const res = await axios.post(CALL_API.ADMIN_VALIDATE, {
        validate: type,
        data,
      })
      setLogin(true)
    } catch (err) {
      setLogin(false)
      notification.error({
        message: 'error',
        description: err.response.data,
        placement: 'top',
        style: { width: '50vw' },
      })
    } finally {
      setLoading(false)
    }
  }

  const timeSearch = (date, dateString) => {
    setTime(dateString)
  }

  const nameSearch = (e) => {
    setSearch(e.target.value)
  }

  const filterElement = (type, label, fx) => {
    let render
    switch (type) {
      case 'input':
        render = <Input className="m-left-15 input-field" onChange={fx} />
        break
      case 'range_date':
        render = (
          <RangePicker className="m-left-15 picker-field" onChange={fx} />
        )
        break
      default:
        break
    }
    return (
      <div className="input-element">
        {label}
        {render}
      </div>
    )
  }

  const dateInRange = (start, end, dateString) => {
    const dateFormat = (string) => {
      return new Date(string)
    }
    const date = dateFormat(dateString)
    const dateStart = dateFormat(start)
    const dateEnd = dateFormat(end)

    return dateStart < date && date < dateEnd
  }

  const searchAction = () => {
    setLoading(true)
    let temp = arr
    if (search) {
      temp = temp
        .filter((val) =>
          val.course.course_name.toLowerCase().includes(search.toLowerCase()),
        )
        .map((result) => result)
    }
    if (time.length) {
      temp = temp
        .filter((val) =>
          dateInRange(time[0], time[1], val.course.class_time.split(' ')[0]),
        )
        .map((result) => result)
    }
    setData(temp)

    setLoading(false)
  }

  // const breadcrumbList = [{ title: 'List User' }]

  const onFinish = (rawdata) => {
    validateAdmin(rawdata, VALIDATEADMINTYPE.LOGIN)
  }

  const LoginPage = () => {
    return (
      <div className="signin-box">
        <div className="signin-input">
          <h1 className="signin-header center">Scratch Lab Admin</h1>
          <Form onFinish={onFinish}>
            <Form.Item name="username">
              <div className="signin-field">
                <input
                  className="text-input"
                  id="username"
                  type="text"
                  required
                />
                <label className="placeholder">Username</label>
              </div>
            </Form.Item>

            <Form.Item name="password">
              <div className="signin-field">
                <input
                  className="text-input"
                  id="password"
                  type="password"
                  required
                />
                <label className="placeholder">Password</label>
              </div>
            </Form.Item>
            {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin spinning={loading}></Spin>
              </div>
            ) : (
              <button className="btnSignin" type="submit">
                Sign In
              </button>
            )}
          </Form>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      {!login ? (
        <LoginPage />
      ) : (
        <div id="admin-page">
          <AdminHeader />
          <Content className="site-layout">
            {/* <AdminBreadcrumbMenu breadcrumbList={breadcrumbList} /> */}
            <Spin spinning={loading}>
              <div>
                <div className="row-items">
                  {filterElement('input', 'Filter: Course Name', nameSearch)}
                  <Divider type="vertical" className="height-auto" />
                  {filterElement('range_date', 'Date Time', timeSearch)}
                  <Button icon={<SearchOutlined />} onClick={searchAction} />
                </div>
                <Table
                  className="m-top-20"
                  columns={columns}
                  dataSource={data}
                />
              </div>
              <ModalTransactionPage
                slipView={slipView}
                setSlipView={setSlipView}
                id={id}
                fetchList={fetchList}
                setIsValid={setIsValid}
                navigate={navigate}
              />
            </Spin>
          </Content>
        </div>
      )}
    </Layout>
  )
}

export default AdminPage
