import { Spin, Layout, Card, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'
import TopHeader from '../elements/topHeader'
import SectionHeader from '../elements/sectionHeader'
import BottomFooter from '../elements/bottomFooter'
import BreadcrumbMenu from '../elements/breadcrumbMenu'
import Review from '../elements/review'
import axios from 'axios'
import { useEffect, useState } from 'react'

import { CALL_API, showNotification } from '../../constant'

const firstPageHeader = {
  top: `สร้างโปรเจค Scratch สุดสนุก \n เขียน Code ได้ \n เห็นชิ้นงานจริงใน 1 ชั่วโมง`,
  description: '',
  bottom: `คอร์ส Scratch ออนไลน์สุดสนุก Coding ได้ทุกที่ สนุกทุกเวลา \n เปลี่ยนทุกจินตนาการให้เป็นจริงที่ ScratchLab`,
}

const ThemePage = () => {
  const [data, setData] = useState([])
  const [reviewList, setReviewList] = useState([])
  const [loading, setLoading] = useState(false)

  const { Meta } = Card
  const { Content } = Layout
  const navigate = useNavigate()
  const breadcrumbList = [{ title: <HomeOutlined /> }]

  useEffect(() => {
    fetchTheme()
  }, [])

  const fetchTheme = async () => {
    setLoading(true)
    try {
      const result = await axios.get(CALL_API.LISTTHEME)
      setData(result.data)
      const review = await axios.get(CALL_API.LISTREVIEW)
      setReviewList(review.data)
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const cardDescription = (val) => {
    return (
      <>
        <div className="text-highlight m-bottom-15">{val.description}</div>
        <div className="center">
          <Button
            className="default-button cta-button"
            onClick={() => {
              navigate(`/theme/course`, {
                state: {
                  id: val.id,
                  theme_name: val.theme_name,
                  courses: val.courses,
                  theme_image: val.image,
                  theme_description: val.description,
                },
              })
            }}
          >
            ดูคลาสเรียนทั้งหมด
          </Button>
        </div>
      </>
    )
  }

  return (
    <Layout>
      <TopHeader />
      <SectionHeader
        topText={firstPageHeader.top}
        description={firstPageHeader.description}
        bottomText={firstPageHeader.bottom}
      />
      <Spin spinning={loading}>
        <Content id="theme-page" className="site-layout">
          <BreadcrumbMenu breadcrumbList={breadcrumbList} />
          <div className="content-bg">
            {data.map((val) => {
              return (
                <Card
                  key={val.id}
                  className="card-item"
                  hoverable
                  cover={
                    <img
                      className="main-card-cover"
                      src={val.image}
                      alt={val.theme_name}
                    />
                  }
                  // onClick={() => {
                  //   navigate(`/theme/course`, {
                  //     state: {
                  //       id: val.id,
                  //       theme_name: val.theme_name,
                  //       courses: val.courses,
                  //       theme_image: val.image,
                  //       theme_description: val.description,
                  //     },
                  //   })
                  // }}
                >
                  <Meta
                    title={<div className="center">{val.theme_name}</div>}
                    description={cardDescription(val, navigate)}
                  />
                </Card>
              )
            })}
          </div>
        </Content>
        <Review dataList={reviewList} />
      </Spin>
      <BottomFooter />
    </Layout>
  )
}

export default ThemePage
