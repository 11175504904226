import { Breadcrumb } from 'antd'
import { HomeOutlined, CodeOutlined } from '@ant-design/icons'
import { formItemLayout } from '../../constant'

const BreadcrumbMenu = ({ breadcrumbList }) => {
  let breadcrumbLevel = [
    {
      // title: <CodeOutlined onClick={() => (window.location.href = '/')} />,
    },
  ]
  breadcrumbList.forEach((element) => {
    breadcrumbLevel.push(element)
  })
  return (
    <Breadcrumb className="breadcrumb" separator=">" items={breadcrumbLevel} />
  )
}

export default BreadcrumbMenu
