import { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  TimePicker,
  Upload,
  Spin,
  Layout,
  Space,
} from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { HomeOutlined } from '@ant-design/icons'
import {
  CALL_API,
  formItemLayout,
  regex_email,
  regex_phone,
  rules,
  showNotification,
} from '../../constant'
import { useLocation, useNavigate } from 'react-router-dom'
import TopHeader from '../elements/topHeader'
import BottomFooter from '../elements/bottomFooter'
import BreadcrumbMenu from '../elements/breadcrumbMenu'

const ReservePage = () => {
  const [slipDate, setSlipDate] = useState('')
  const [slip, setSlip] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorSlip, setErrorSlip] = useState(false)
  const [bank, setBank] = useState({})

  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const { Content } = Layout

  const {
    theme_id,
    theme_name,
    class_id,
    course_name,
    level,
    class_date,
    class_time,
    teacher_name,
    price,
    courses,
  } = location?.state || {}

  const breadcrumbList = [
    { title: <HomeOutlined />, href: '/theme' },
    {
      title: (
        <a
          onClick={() => {
            navigate(`/theme/course`, {
              state: {
                id: theme_id,
                theme_name,
                courses,
              },
            })
          }}
        >
          {theme_name}
        </a>
      ),
    },
    {
      title: (
        <a
          onClick={() => {
            navigate(`/theme/course/class`, {
              state: {
                theme_id,
                theme_name,
                class_id,
                course_name,
                level,
                price,
                courses,
              },
            })
          }}
        >
          {course_name}
        </a>
      ),
    },
    { title: 'Reserve' },
  ]

  useEffect(() => {
    if (!location?.state) {
      navigate(`/theme`, {})
    }
  }, [])

  // const rules = (name) => {
  //   return [{ required: true, message: `Please filled ${name}!` }]
  // }

  useEffect(() => {
    getBank()
  }, [])

  const getBank = async () => {
    setLoading(true)
    try {
      const result = await axios.get(CALL_API.BANK)
      const { BANKID, BANKNAME, BANKACCOUNT } = result.data
      setBank({
        BANKID,
        BANKNAME,
        BANKACCOUNT,
      })
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const normFile = (e) => {
    return e
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        setSlip(reader.result)
      })
      onSuccess('ok')
    }, 0)
  }

  const clickCreate = async (data) => {
    setLoading(true)
    const body = {
      course_name: data.course_name,
      level: data.level,
      theme_name: data.theme_name,
      class_time: data.class_time,
      teacher: teacher_name,
      phone: data.phone,
      price: parseInt(data.price),
      transaction: `${slipDate}`,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      slip_Payment: slip,
    }
    try {
      const result = await axios.post(CALL_API.CREATE, body)
      console.log(result.data)
      if (result.data?.message) {
        showNotification('info', result.data.message)
      } else {
        showNotification(
          'success',
          'Your reservation has been successful. We will send invitation link to your email.',
        )
        navigate('/theme')
      }
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = (data) => {
    if (slip !== '') clickCreate(data)
  }

  const onChangeTransaction = (date, dateString) => {
    setSlipDate(dateString)
  }
  // const onChangeClassDate = (date, dateString) => {
  //   setClassDate(dateString)
  // }
  // const onChangeClassTime = (date, dateString) => {
  //   setClassTime(`${dateString[0]} - ${dateString[1]}`)
  // }

  return (
    <Layout>
      <TopHeader />
      <Content id="reserve-page" className="site-layout">
        <BreadcrumbMenu breadcrumbList={breadcrumbList} />
        <Spin spinning={loading}>
          <div className="m-bottom-20 flex-center">
            <span className="page-header">คอนเฟิร์มจองรอบ</span>
          </div>
          <Form
            className="form-container"
            {...formItemLayout}
            scrollToFirstError
            form={form}
            onFinish={onFinish}
            initialValues={{
              course_name: course_name,
              class_time: `${class_date} ${class_time}`,
              level: level,
              theme_name: theme_name,
              teacher_name: teacher_name,
              price: price,
            }}
          >
            <div className="form-section-header section-info">
              รายละเอียดคลาส
            </div>
            <div className="form-section m-bottom-20">
              <Form.Item label="ชื่อคอร์ส" name="course_name">
                <Space>{course_name}</Space>
              </Form.Item>
              <Form.Item label="ระดับ" name="level">
                <Space>{level}</Space>
              </Form.Item>
              <Form.Item label="ธีมที่เลือก" name="theme_name">
                <Space>{theme_name}</Space>
              </Form.Item>
              <Form.Item label="เวลาเรียน" name="class_time">
                <div className="flex" style={{ gap: 10 }}>
                  {/* <DatePicker
                    value={dayjs(class_date, 'YYYY-MM-DD')}
                    // onChange={onChangeClassDate}
                    disabled
                  /> */}
                  <Space>{class_date}</Space>
                  {/* <TimePicker.RangePicker
                    value={[
                      dayjs(class_time?.split('-')[0], 'HH:mm'),
                      dayjs(class_time?.split('-')[1], 'HH:mm'),
                    ]}
                    // onChange={onChangeClassTime}
                    format="HH:mm"
                    disabled
                  /> */}
                  <Space>{class_time}</Space>
                </div>
              </Form.Item>
              <Form.Item label="คุณครู" name="teacher_name">
                {/* <Input disabled /> */}
                <Space>{teacher_name}</Space>
              </Form.Item>
            </div>
            <div className="form-section-header section-additional">
              ข้อมูลเพิ่มเติม
            </div>
            <div className="form-section m-bottom-20">
              <Form.Item
                label="ชื่อ"
                name="firstname"
                rules={rules('Firstname')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="นามสกุล"
                name="lastname"
                rules={rules('Lastame')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="อีเมล์"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please filled Email!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const regex = regex_email
                      if (
                        !value ||
                        String(value).toLowerCase().match(regex) !== null
                      ) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('Invalid Email!'))
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="เบอร์โทรศัพท์"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please filled Phone Number!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const regex = regex_phone
                      if (!value || regex.test(value)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('Invalid Phone Number!'))
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="form-section-header section-payment">
              รายละเอียดการชำระเงิน
            </div>
            <div className="form-section m-bottom-20">
              <Form.Item label="เลขบัญชี" name="accountID">
                <Space>{bank.BANKNAME ? bank.BANKNAME : '-'}</Space>
              </Form.Item>
              <Form.Item label="เลขบัญชี" name="accountID">
                <Space>{bank.BANKID ? bank.BANKID : '-'}</Space>
              </Form.Item>
              <Form.Item label="ชื่อบัญชี" name="accountName">
                <Space>{bank.BANKACCOUNT ? bank.BANKACCOUNT : '-'}</Space>
              </Form.Item>
              <Form.Item label="ราคา" name="price">
                <Space>{price}</Space>
              </Form.Item>
              <Form.Item
                label="วันและเวลาที่โอนเงิน"
                name="transaction"
                rules={rules('DatePicker')}
              >
                <DatePicker
                  className="full-width"
                  showTime
                  onChange={onChangeTransaction}
                />
              </Form.Item>
              <Form.Item
                rules={rules('Payment')}
                label="แนบสลิป"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture"
                  customRequest={dummyRequest}
                  onChange={(e) => {
                    if (e.fileList.length === 0) {
                      setSlip('')
                    }
                  }}
                >
                  {slip === '' && (
                    <Button className="default-button">Choose file</Button>
                  )}
                </Upload>
                {errorSlip && slip === '' && (
                  <span style={{ color: 'red' }}>
                    กรุณาอัพโหลดหลักฐานการชำระเงิน
                  </span>
                )}
              </Form.Item>
            </div>
            <Form.Item className="flex-center">
              <div className="flex gap-15">
                <Button
                  className="default-button blue-button large-button cta-button"
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (slip === '') {
                      setErrorSlip(true)
                    } else {
                      setErrorSlip(false)
                    }
                  }}
                >
                  ยืนยัน
                </Button>
                <Button
                  className="default-button yellow-button large-button cta-button"
                  onClick={() => {
                    navigate(`/theme`, {})
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Content>
      <BottomFooter />
    </Layout>
  )
}

export default ReservePage
