import {  } from 'antd'
const image = require('../../img/page-img/main-img.png');

const SectionHeader = ({ bgImage, topText, description, bottomText }) => {
  const splitNewLine = (textItem) => {
    return (
      textItem && textItem.split('\n').map((item) => {
        return <div key={item}>{item}<br/></div>
      })
    );
  }
  return (
    <div
      className='site-layout'
    >
      <div className={`section-header ${bgImage ? 'info' : ''}`}>
        <div className={`${bgImage ? 'section-header-container' : 'landing'}`}>
          <div className='section-header-title'>
            { splitNewLine(topText) }
          </div>
          <div className='m-top-15 section-header-description'>
            { splitNewLine(description) }
          </div>
          <div className='m-top-15 section-header-optional'>
            { splitNewLine(bottomText) }
          </div>
        </div>
        <img
          className='section-header-bg'
          src={bgImage || image}
          alt=''
        />
      </div>
    </div>
  )
}

export default SectionHeader
