import { useEffect, useState, } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Layout, Card, Spin } from 'antd'
import TopHeader from '../elements/topHeader'
import SectionHeader from '../elements/sectionHeader'
import BottomFooter from '../elements/bottomFooter'
import BreadcrumbMenu from '../elements/breadcrumbMenu'
import { CALL_API, showNotification } from '../../constant'

const CoursePage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const { Meta } = Card
  const { Content } = Layout
  const location = useLocation()
  const navigate = useNavigate()
  const { id, theme_name, courses, theme_image, theme_description } =
    location?.state || {}
  const breadcrumbList = [
    { title: <HomeOutlined />, href: '/theme' },
    { title: `${theme_name}` },
  ]

  useEffect(() => {
    if (location?.state) {
      fetchClass()
    } else {
      navigate(`/theme`, {})
    }
  }, [])

  const fetchClass = async () => {
    setLoading(true)
    try {
      const result = await axios.post(CALL_API.LISTCOURSE, { courses })
      setData(result.data)
    } catch (err) {
      const error_msg = err?.response?.data || err.message
      showNotification('error', error_msg)
    } finally {
      setLoading(false)
    }
  }

  const openClass = (val) => {
    if(val.price === 0 && val?.link) {
      return window.open(val?.link, '_blank')
    } else {
      return navigate(`/theme/course/class`, {
        state: {
          theme_id: id,
          class_id: val.id,
          course_name: val.course_name,
          level: val.level,
          theme_name: theme_name,
          price: val.price,
          courses,
          theme_image,
          theme_description,
        },
      })
    }
  }

  return (
    <Layout>
      <TopHeader />
      <SectionHeader
        bgImage={theme_image}
        topText={theme_name}
        description={theme_description}
      />
      <Spin spinning={loading}>
        <Content id="course-page" className="site-layout">
          <BreadcrumbMenu breadcrumbList={breadcrumbList} />
          <div className="content-bg">
            {data.map((val) => {
              return (
                <Card
                  key={val.id}
                  className="card-item cta-button"
                  hoverable
                  cover={
                    <img
                      className="main-card-cover"
                      src={val.image}
                      alt={val.level}
                    />
                  }
                  actions={[
                    <div className="text-highlight">
                      <SettingOutlined /> Level {val.level}
                    </div>,
                    // <div className='text-highlight'>
                    //   <FieldTimeOutlined /> {val.duration} mins
                    // </div>,
                    // <div className='text-highlight'>
                    //   <DollarOutlined /> {val.price}
                    // </div>,
                  ]}
                  onClick={() => {
                    openClass(val);
                  }}
                >
                  <Meta
                    title={<div className="center">{val.course_name}</div>}
                    description={
                      <div className="text-hover ">{val.description}</div>
                    }
                  />
                </Card>
              )
            })}
          </div>
        </Content>
      </Spin>
      <BottomFooter />
    </Layout>
  )
}

export default CoursePage
