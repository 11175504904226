import {
  Divider,
  Layout
} from 'antd'
import {
  EnvironmentFilled,
} from '@ant-design/icons';
const qrImage = require('../../img/contact/qr-line.png');
const fb = require('../../img/contact/contact-fb.png');
const ig = require('../../img/contact/contact-ig.png');
const line = require('../../img/contact/contact-line.png');
const messenger = require('../../img/contact/contact-messenger.png');

const contactList = [
  {
    image: fb,
    link: 'https://www.facebook.com/codegeniusacademy/',
    alt: 'Facebook Scratch Lab',
  },
  {
    image: line,
    link: 'https://lin.ee/hx062UM',
    alt: 'Line Scratch Lab',
  },
  {
    image: ig,
    link: 'https://instagram.com/codegenius_official',
    alt: 'Instragram Scratch Lab',
  },
  {
    image: messenger,
    link: 'https://m.me/codegeniusacademy',
    alt: 'Messenger Scratch Lab',
  },
];

const locationList = [
  {
    name: 'Siam Paragon',
  },
  {
    name: 'Central Pinklao',
  },
  {
    name: 'Emquartier',
  },
  {
    name: 'Korat',
  },
  {
    name: 'Nichada',
  },
  {
    name: 'Ubon Ratchathani',
  },
];

const BottomFooter = () => {
  const { Footer } = Layout;
  return (
    <Footer
      className='default-footer'
    >
      <div>ติดต่อเรา</div>
      <div className='container-flex m-top-15 m-bottom-15'>
        <img
          className='qr-code'
          src={qrImage}
          alt='Scratch Lab'
        />
        <Divider type='vertical' className='full-divider'/>
        <div className='contact social'>
          {contactList.map(item => 
            <div className='contact-item' key={item.link}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img
                  className='contact-img'
                  src={item.image}
                  alt={item.alt}
                />
              </a>
            </div>
          )}
        </div>
        <Divider type='vertical' className='full-divider'/>
        <div className='contact location'>
          {locationList.map(location => 
            <div className='contact-item' key={location.name}>
              <EnvironmentFilled /><span className='contact-text'>{location.name}</span>
            </div>
          )}
        </div>
      </div>
      <div className='info-text'>Copyright 2023</div>
    </Footer>
  )
}

export default BottomFooter
