import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { CALL_API } from '../../constant'
import { AdminContext } from '../../App'

const RedirectPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { uid } = params

  const { setIsValid } = useContext(AdminContext)

  useEffect(() => {
    redirectAdmin()
  }, [])

  const redirectAdmin = async () => {
    try {
      const result = await axios.get(CALL_API.ADMIN_APPROVAL(uid))
      if (result.data) {
        // document.cookie = `redirect=${result.data.redirect};path=/`
        setIsValid(true)
        navigate(result.data.redirect)
        // window.location.href = result.data.redirect
      }
    } catch (err) {
      console.log(err)
      setIsValid(false)
      window.location.href = `/error`
    }
  }

  return <></>
}

export default RedirectPage
